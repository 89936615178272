import logo from "../static/logo.svg";

// import ellipse from "../static/professional/ellipse.webp";
// import ellipsemobile from "../static/professional/ellipse-mobile.png";

// import background from "../static/professional/background.png";
import headerimage1 from "../static/professional/header-image1.webp";
import sectionimage1 from "../static/professional/section-image1.webp";
import sectionimage2 from "../static/professional/section-image2.webp";
import sectionimage3 from "../static/professional/section-image3.webp";
//hq svg images
import headerimage1hq from "../static/professional/header-image1.svg";
import sectionimage1hq from "../static/professional/section-image1.svg";
import sectionimage2hq from "../static/professional/section-image2.svg";
import sectionimage3hq from "../static/professional/section-image3.svg";
import arrowright from "../static/professional/arrow-right.svg";
import { isMobile } from "react-device-detect";
import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import axios from "axios";

import FooterMobile from "./mobile/Footer";
import FooterDesktop from "./desktop/Footer";
import { Helmet } from "react-helmet";

const canonicalUrl = "https://moongig.xyz";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const API_SECRET = process.env.REACT_APP_MOONGIG_SECRET;

// styles 1366 above

const styleXL = {
  logoImg: {
    height: "70px",
    width: "240px",
  },
  mainDiv: {
    margin: "-8px",
    fontFamily: "sans-serif",
  },
  bannerDiv: {
    padding: "70px 100px",
    backgroundColor: "#161C2D",
    backgroundSize: "cover",
  },
  ellipse: {
    position: "absolute",
    marginTop: "-220px",
    marginLeft: "-100px",
    zIndex: 0,
    height: "500px",
    width: "900px",
    background:
      "radial-gradient(ellipse at left top, rgba(35, 206, 133, 0.6) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0))",
  },
  headerButtonCont: {
    display: "inline-block",
    flexGrow: 1,
    textAlign: "right",
    padding: "6px",
    zIndex: 1,
  },
  headerButton1: {
    padding: "16px 32px",
    backgroundColor: "transparent",
    border: 0,
    borderRadius: 8,
    fontSize: "17px",
    fontWeight: "bold",
    color: "#FFF",
  },
  headerButton2: {
    padding: "16px 32px",
    backgroundColor: "#23CE85",
    marginLeft: "12px",
    border: 0,
    borderRadius: 8,
    fontSize: "17px",
    fontWeight: "bold",
    color: "#161C2D",
  },
  bannerFlex: {
    display: "flex",
    paddingTop: "70px",
    zIndex: 1,
  },
  bannerFlexCont1: {
    width: "55%",
    textAlign: "left",
    paddingRight: "40px",
    zIndex: 1,
  },
  bannerFlexContText1: {
    fontSize: "48px",
    fontWeight: "bold",
    color: "#929ED6",
    paddingTop: "40px",
    lineHeight: "64px",
  },
  bannerFlexContText2: {
    paddingTop: "40px",
    fontSize: "32px",
    color: "rgba(255, 255, 255, 0.75)",
    lineHeight: "50px",
    fontWeight: "400",
    paddingRight: "10px",
  },
  bannerFlexContText3: {
    marginTop: "60px",
    fontSize: "28px",
    color: "#68D585",
    lineHeight: "50px",
    fontWeight: "bold",
    paddingRight: "10px",
  },
  bannerFlexContText3Arrow: {
    width: "20px",
    marginLeft: "20px",
  },
  bannerFlexCont2: {
    flexGrow: 1,
    paddingLeft: "40px",
  },

  sectionDiv: {
    padding: "40px 100px",
  },
  sectionSubDiv: {
    display: "flex",
    paddingTop: "70px",
  },
  sectionSubDivReversed: {
    display: "flex",
    paddingTop: "70px",
    flexDirection: "row-reverse",
  },
  sectionSubDivContTable: {
    width: "50%",
    textAlign: "left",
    paddingRight: "40px",
    display: "table",
  },
  sectionSubDivContCell: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  sectionSubDivContHeader: {
    fontSize: "48px",
    fontWeight: "bold",
    color: "#161C2D",
    lineHeight: "64px",
  },
  sectionSubDivContText: {
    paddingTop: "40px",
    fontSize: "32px",
    color: "rgba(0, 0, 0, 0.75)",
    lineHeight: "50px",
    fontWeight: "400",
    paddingRight: "10px",
  },
  sectionSubDivImage: {
    flexGrow: 1,
    paddingLeft: "40px",
  },

  signupDiv: {
    padding: "80px 100px",
    backgroundColor: "#EFF3FB",
  },

  signupDivCont: {
    display: "flex",
    padding: "36px 100px",
    backgroundColor: "#161C2D",
    borderRadius: "16px",
  },
  signupDivCont1: {
    width: "48%",
    textAlign: "left",
    paddingRight: "40px",
  },
  signupDivContCell: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  singupDivContText: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#48D89A",
    lineHeight: "48px",
  },
  signupDivContTable2: {
    flexGrow: 1,
    paddingLeft: "40px",
    display: "table",
    paddingBottom: "20px",
  },
  signupDivButton: {
    padding: "16px 32px",
    backgroundColor: "#23CE85",
    border: 0,
    borderRadius: "8px",
    fontSize: "17px",
    fontWeight: "bold",
    color: "#111928",
    marginTop: "40px",
  },
  signupDivButton2: {
    padding: "16px 32px",
    backgroundColor: "#FFF",
    border: 0,
    borderRadius: "8px",
    fontSize: "17px",
    fontWeight: "bold",
    color: "#111928",
    marginTop: "40px",
  },
  signupDivFlexReversed: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  signupDivInput: {
    height: "40px",
    borderRadius: "8px",
    border: "1px",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginBottom: "20px",
  },
  signupDivInputError: {
    height: "40px",
    borderRadius: "8px",
    border: "1px solid red",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginBottom: "20px",
    backgroundColor: "#ffdddd",
  },
  signupDivInputLabel: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#FFF",
    marginBottom: "10px",
  },
  signupDivInputCont: {
    flexGrow: 1,
    paddingRight: "24px",
  },
  signupDivSelectCont: {
    flexGrow: 1,
    paddingRight: "5px",
  },
  signupDivSelect: {
    height: "40px",
    borderRadius: "8px",
    border: "1px",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "10px",
    marginBottom: "20px",
    borderRight: "10px solid transparent",
    marginRight: "10px",
    color: "#000",
  },
  signupDivSelectDisabled: {
    height: "40px",
    borderRadius: "8px",
    border: "1px",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "10px",
    marginBottom: "20px",
    borderRight: "10px solid transparent",
    marginRight: "10px",
    color: "#94A3B8",
  },
  singupDivContSubmitted: {
    fontSize: "32px",
    fontWeight: "bold",
    lineHeight: "48px",
    color: "#FFF",
    width: "100%",
    maxWidth: "500px",
    height: "222px",
    display: "table",
  },
};

const styleMed = {
  headerButton1: {
    padding: "16px 32px",
    backgroundColor: "transparent",
    border: 0,
    borderRadius: 8,
    fontSize: "17px",
    fontWeight: "bold",
    color: "#FFF",
  },
  headerButton2: {
    padding: "16px 32px",
    backgroundColor: "#23CE85",
    marginLeft: "12px",
    border: 0,
    borderRadius: 8,
    fontSize: "17px",
    fontWeight: "bold",
    color: "#161C2D",
  },
  bannerFlexContText1: {
    fontSize: "36px",
    fontWeight: "bold",
    color: "#929ED6",
    paddingTop: "30px",
    lineHeight: "48px",
  },
  bannerFlexContText2: {
    paddingTop: "20px",
    fontSize: "24px",
    color: "rgba(255, 255, 255, 0.75)",
    lineHeight: "36px",
    fontWeight: "400",
    paddingRight: "10px",
  },
  bannerFlexContText3: {
    marginTop: "40px",
    fontSize: "24px",
    color: "#68D585",
    lineHeight: "40px",
    fontWeight: "bold",
    paddingRight: "10px",
  },
  bannerFlexContText3Arrow: {
    width: "14px",
    marginLeft: "16px",
  },
  sectionSubDivContHeader: {
    fontSize: "36px",
    fontWeight: "bold",
    color: "#161C2D",
    lineHeight: "48px",
  },
  sectionSubDivContText: {
    paddingTop: "30px",
    fontSize: "24px",
    color: "rgba(0, 0, 0, 0.75)",
    lineHeight: "36px",
    fontWeight: "400",
    paddingRight: "10px",
  },
  singupDivContText: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#48D89A",
    lineHeight: "36px",
  },
};

// mobile styles
const styleMobile = {
  logoImg: {
    height: "40px",
    width: "136px",
  },
  mainDiv: {
    margin: "-8px",
    fontFamily: "sans-serif",
  },
  bannerDiv: {
    padding: "20px 20px",
    backgroundColor: "#161C2D",
    backgroundSize: "cover",
  },
  ellipse: {
    position: "absolute",
    marginTop: "-60px",
    marginLeft: "-20px",
    zIndex: 0,
    height: "400px",
    width: "100%",
    background:
      "radial-gradient(at left top, rgba(35, 206, 133, 0.4) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0))",
  },
  headerButtonCont: {
    display: "inline-block",
    flexGrow: 1,
    textAlign: "right",
    padding: "6px",
    zIndex: 1,
  },
  headerButton1: {
    padding: "8px 16px",
    backgroundColor: "transparent",
    border: 0,
    borderRadius: 8,
    fontSize: "12px",
    fontWeight: "bold",
    color: "#FFF",
  },
  bannerFlex: {
    paddingTop: "30px",
    zIndex: 1,
  },
  bannerFlexCont1: {
    textAlign: "left",
    zIndex: 1,
  },
  bannerFlexContText1: {
    paddingTop: "10px",
    fontSize: "32px",
    fontWeight: "bold",
    color: "#929ED6",
    lineHeight: "48px",
  },
  bannerFlexContText2: {
    paddingTop: "20px",
    fontSize: "20px",
    color: "rgba(255, 255, 255, 0.75)",
    lineHeight: "32px",
    fontWeight: "400",
  },
  bannerFlexContText3: {
    marginTop: "40px",
    fontSize: "21px",
    color: "#68D585",
    lineHeight: "50px",
    fontWeight: "bold",
    paddingRight: "10px",
  },
  bannerFlexContText3Arrow: {
    width: "14px",
    marginLeft: "12px",
  },
  bannerFlexCont2: {
    paddingBottom: "40px",
  },

  sectionDiv: {
    padding: "20px",
  },
  sectionSubDiv: {
    paddingTop: "50px",
  },
  sectionSubDivReversed: {
    paddingTop: "20px",
    flexDirection: "row-reverse",
  },
  sectionSubDivContTable: {
    textAlign: "left",
    display: "table",
  },
  sectionSubDivContCell: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  sectionSubDivContHeader: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#161C2D",
    lineHeight: "48px",
  },
  sectionSubDivContText: {
    paddingTop: "20px",
    fontSize: "20px",
    color: "rgba(0, 0, 0, 0.75)",
    lineHeight: "32px",
    fontWeight: "400",
    paddingRight: "10px",
    paddingBottom: "10px",
  },
  sectionSubDivImage: {
    flexGrow: 1,
    paddingBottom: "20px",
  },
  signupDiv: {
    padding: "20px",
    backgroundColor: "#EFF3FB",
  },

  signupDivCont: {
    padding: "20px",
    backgroundColor: "#161C2D",
    borderRadius: "16px",
  },
  signupDivContTable: {
    textAlign: "left",
    display: "table",
  },
  signupDivContCell: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  singupDivContText: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#48D89A",
    lineHeight: "48px",
  },
  signupDivContTable2: {
    width: "100%",
    paddingTop: "20px",
    display: "table",
    paddingBottom: "20px",
  },
  signupDivButton: {
    width: "100%",
    padding: "16px 20px",
    backgroundColor: "#23CE85",
    border: 0,
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#111928",
    marginTop: "30px",
  },
  signupDivButton2: {
    padding: "16px 32px",
    backgroundColor: "#FFF",
    border: 0,
    borderRadius: "8px",
    fontSize: "17px",
    fontWeight: "bold",
    color: "#111928",
    marginTop: "40px",
  },
  signupDivFlexReversed: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  signupDivInput: {
    height: "40px",
    borderRadius: "8px",
    border: "1px",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginBottom: "20px",
  },

  signupDivInputError: {
    height: "40px",
    borderRadius: "8px",
    border: "1px solid red",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginBottom: "20px",
    backgroundColor: "#ffdddd",
  },
  signupDivInputLabel: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#FFF",
    marginBottom: "10px",
  },
  signupDivInputCont: {
    flexGrow: 1,
    paddingRight: "24px",
  },
  signupDivSelectCont: {
    flexGrow: 1,
    paddingRight: "5px",
  },
  signupDivSelect: {
    height: "40px",
    borderRadius: "8px",
    border: "1px",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "0px",
    marginBottom: "20px",
    borderRight: "10px solid transparent",
    marginRight: "10px",
    color: "#000",
  },
  signupDivSelectDisabled: {
    height: "40px",
    borderRadius: "8px",
    border: "1px",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "10px",
    marginBottom: "20px",
    borderRight: "10px solid transparent",
    marginRight: "10px",
    color: "#94A3B8",
  },
  singupDivContSubmitted: {
    fontSize: "32px",
    fontWeight: "bold",
    lineHeight: "48px",
    color: "#FFF",
    width: "100%",
    maxWidth: "500px",
    height: "222px",
    display: "table",
  },
};

const LandingProfessional = () => {
  // const formRef = useRef(null);
  const imgRef = useRef(null);
  const imgRef1 = useRef(null);
  const imgRef2 = useRef(null);
  const imgRef3 = useRef(null);
  const [showImg, setShowImg] = useState(false);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  // dimensions
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // const executeScroll = () => {
  //   // Check if the URL has a hash
  //   if (window.location.hash) {
  //     const targetElementId = window.location.hash.slice(1); // Remove the '#' symbol
  //     const targetElement = document.getElementById(targetElementId);

  //     if (targetElement) {
  //       // Scroll to the target element after a delay to ensure layout is complete
  //       setTimeout(() => {
  //         targetElement.scrollIntoView({
  //           behavior: "smooth",
  //           block: "start",
  //         });

  //         // Handle mobile Safari toolbar offset (iOS-specific fix)
  //         if (
  //           navigator.userAgent.match(/iPhone|iPad|iPod/i) &&
  //           window.innerWidth <= 768
  //         ) {
  //           const toolbarHeight = 80; // Adjust based on your design
  //           window.scrollBy(0, -toolbarHeight);
  //         }
  //       }, 100); // Delay to ensure the element is fully rendered
  //     }
  //   }
  // };

  const executeScroll = () => {
    // Check if the URL has a hash (e.g., #registration-form)
    if (window.location.hash) {
      const targetElementId = window.location.hash.slice(1); // Remove '#' symbol
      const targetElement = document.getElementById(targetElementId);

      if (targetElement) {
        // Delay scrolling to ensure the layout is complete, especially on iOS
        setTimeout(() => {
          // Scroll to the target element smoothly
          // targetElement.scrollIntoView({
          //   behavior: "smooth",
          //   block: "start",
          // });
          targetElement.scrollIntoView();

          // Handle iOS Safari toolbar offset (for bottom toolbar visibility)
          // if (isIOS()) {
          //   const toolbarHeight = getToolbarHeight();
          //   setTimeout(() => {
          //     window.scrollBy(0, -toolbarHeight); // Adjust scroll position by the toolbar height
          //   }, 100); // Delay to ensure the toolbar height is accounted for
          // }
        }, 100); // Initial delay to allow elements to load and layout to settle

        // Adjust scroll position for fixed header (e.g., if header height is 80px)
        const headerHeight = 80; // Adjust this value based on your header's height
        window.scrollBy(0, -headerHeight); // Offset the scroll by the header's height
      }

      // if (targetElement) {
      //   // Disable scroll anchoring on iOS devices
      //   if (isIOS()) {
      //     disableScrollAnchoring();
      //   }

      //   // Delay scrolling to ensure the layout is complete, especially on iOS
      //   setTimeout(() => {
      //     // Scroll to the target element smoothly
      //     targetElement.scrollIntoView({
      //       behavior: "smooth",
      //       block: "start",
      //     });

      //     // Handle iOS Safari toolbar offset (for bottom toolbar visibility)
      //     if (isIOS()) {
      //       const toolbarHeight = getToolbarHeight();
      //       setTimeout(() => {
      //         window.scrollBy(0, -toolbarHeight); // Adjust scroll position by the toolbar height
      //       }, 100); // Delay to ensure the toolbar height is accounted for
      //     }
      //   }, 100); // Initial delay to allow elements to load and layout to settle
      // }
    }
  };
  // Call this function on page load to check if we need to scroll
  window.onload = executeScroll;

  const handleResize = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Trigger the scroll action on page load or hashchange
    executeScroll();

    // Set up event listeners for hashchange and resize
    window.addEventListener("hashchange", executeScroll);
    window.addEventListener("resize", handleResize, false);

    // Optional image preloading and handling for different devices
    if (windowDimensions.width <= 768) {
      setTimeout(() => {
        imgRef.current.src = headerimage1;
        imgRef1.current.src = sectionimage1;
        imgRef2.current.src = sectionimage2;
        imgRef3.current.src = sectionimage3;
        setShowImg(true);
      }, 100);
    } else {
      setTimeout(() => {
        imgRef.current.src = headerimage1hq;
        imgRef1.current.src = sectionimage1hq;
        imgRef2.current.src = sectionimage2hq;
        imgRef3.current.src = sectionimage3hq;
        setShowImg(true);
      }, 100);
    }

    // Clean up the event listeners on unmount
    return () => {
      window.removeEventListener("hashchange", executeScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [windowDimensions]);

  // form variables
  const [name, setName] = useState("");
  const [profession, setProfession] = useState("");
  const [email, setEmail] = useState("");

  const [errorName, setErrorName] = useState(false);
  const [errorProfession, setErrorProfession] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);

  function isEmail(val) {
    var regEmail =
      /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;
    if (!regEmail.test(val)) {
      return false;
    }
    return true;
  }

  async function reloadForm() {
    setFormError(false);
    setFormSubmitted(false);
  }

  const register = async (e) => {
    e.preventDefault();

    setLoading(true);
    let error = false;

    // Validate the name
    if (name === "") {
      error = true;
      setErrorName(true);
    } else {
      setErrorName(false);
    }

    // Validate the profession
    if (profession === "") {
      error = true;
      setErrorProfession(true);
    } else {
      setErrorProfession(false);
    }

    // Validate the email
    if (email === "") {
      error = true;
      setErrorEmail(true);
    } else if (!isEmail(email)) {
      error = true;
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
    }

    // If there are errors, stop the submission
    if (error) {
      setLoading(false);
      return;
    }

    const data = {
      email: email,
      profession: profession.value,
      name: name,
    };

    try {
      const url = `${BASE_URL}/register/professional`;
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-Moongig-Secret": API_SECRET,
        },
      };
      // Perform the POST request
      const result = await axios.post(url, data, config);

      if (result.status !== 200) {
        console.error("Error saving data:", result.data);
        setFormError(true);
      } else {
        // Facebook Pixel Lead Event
        if (window.fbq) {
          window.fbq("track", "Lead");
          console.log('Facebook Pixel "Lead" event triggered');
        } else {
          console.log("Facebook Pixel not loaded");
        }

        setFormError(false);
        console.log("Data saved to Notion!");
      }
    } catch (error) {
      console.error(error);
      setFormError(true);
    }

    // Update form submission state
    setFormSubmitted(true);
    setLoading(false);
  };
  // async function register() {
  //   setLoading(true);
  //   var error = false;
  //   if (name === "") {
  //     error = true;
  //     setErrorName(true);
  //   } else {
  //     setErrorName(false);
  //   }

  //   if (profession === "") {
  //     error = true;
  //     setErrorProfession(true);
  //   } else {
  //     setErrorProfession(false);
  //   }

  //   if (email === "") {
  //     error = true;
  //     setErrorEmail(true);
  //   } else if (!isEmail(email)) {
  //     error = true;
  //     setErrorEmail(true);
  //   } else {
  //     setErrorEmail(false);
  //   }

  //   if (error) {
  //     setLoading(false);
  //     return;
  //   }

  //   var data = {
  //     email: email,
  //     profession: profession.value,
  //     name: name,
  //   };

  //   try {
  //     const url = BASE_URL + "/register/professional";
  //     const config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-Moongig-Secret": API_SECRET,
  //       },
  //     };
  //     var result = await axios.post(url, data, config);

  //     if (result.status !== 200) {
  //       const error = await result.json();
  //       console.error("Got error saving data", error);
  //       setFormError(true);
  //     } else {
  //       if (window.fbq) {
  //         window.fbq("track", "Lead");
  //         console.log('Facebook Pixel "Lead" event triggered');
  //       } else {
  //         console.log("Facebook Pixel not loaded");
  //       }

  //       setFormError(false);
  //       console.log("Data saved to Notion!");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setFormError(true);
  //   }

  //   setFormSubmitted(true);
  //   setLoading(false);
  // }

  // const handleSelectChange = (event) => {
  //     setProfession(event.target.value);
  // }

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const options = [
    { value: "Nurse", label: "Nurse" },
    { value: "Physician", label: "Physician" },
    { value: "Medtech", label: "Medtech" },
    { value: "Radtech", label: "Radtech" },
    { value: "Other", label: "Other" },
  ];

  var stylePro = styleXL;
  if (isMobile) {
    stylePro = styleMobile;
  }

  return (
    <div style={stylePro.mainDiv}>
      {/* helmet */}
      <Helmet>
        <title>
          Moongig - Your platform for flexible jobs in the healthcare space
        </title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          name="title"
          content="Moongig - Your platform for flexible jobs in the healthcare space"
        />
        <meta
          name="description"
          content="Find flexible job opportunities and a sustainable income in the healthcare space."
        />
        <meta
          name="keywords"
          content="moonlighting opportunities, part-time healthcare jobs, flexible medical positions, freelance healthcare work, temporary medical jobs, evening healthcare shifts, weekend medical positions, healthcare gig work, medical professionals network, on-demand healthcare jobs"
        />
        <meta
          property="og:title"
          content="Moongig - Your platform for flexible jobs in the healthcare space"
        />
        <meta property="og:site_name" content="Moongig" />
        <meta property="og:url" content="www.moongig.xyz" />
        <meta
          property="og:description"
          content="Find flexible job opportunities and a sustainable income in the healthcare space."
        />
      </Helmet>
      {/* banner div */}
      <div style={stylePro.bannerDiv} loading="eager">
        <div style={{ display: "flex" }}>
          {/* { showImg ? <img ref={imgRef} alt="" style={stylePro.ellipse}/> :  <img alt="" ref={imgRef} style={stylePro.ellipse} hidden/>} */}
          <div style={stylePro.ellipse}></div>
          <a href="/" style={{ zIndex: 1 }}>
            <img src={logo} alt="logo" style={stylePro.logoImg} />
          </a>
          {isMobile ? (
            <div style={stylePro.headerButtonCont}>
              <a href="/provider">
                <button style={stylePro.headerButton1}>
                  I'm a medical provider
                </button>
              </a>
            </div>
          ) : (
            <div style={stylePro.headerButtonCont}>
              <span
                style={
                  windowDimensions.width > 1366
                    ? stylePro.headerButton1
                    : styleMed.headerButton1
                }
              >
                <a style={{ textDecoration: "none" }} href="provider">
                  I'm a medical provider
                </a>
              </span>

              <button
                style={
                  windowDimensions.width > 1366
                    ? stylePro.headerButton2
                    : styleMed.headerButton2
                }
                onClick={() => {
                  // Scroll to the registration form when button is clicked
                  document.getElementById("registration-form")?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              >
                Register now
              </button>

              {/* <button
                style={
                  windowDimensions.width > 1366
                    ? stylePro.headerButton2
                    : styleMed.headerButton2
                }
                onClick={executeScroll}
              >
                Register now
              </button> */}
            </div>
          )}
        </div>
        <div style={stylePro.bannerFlex}>
          <div style={stylePro.bannerFlexCont1}>
            <div
              style={
                isMobile || windowDimensions.width > 1366
                  ? stylePro.bannerFlexContText1
                  : styleMed.bannerFlexContText1
              }
            >
              Looking for flexible job opportunities and a sustainable income in
              the healthcare space?
            </div>
            <div
              style={
                isMobile || windowDimensions.width > 1366
                  ? stylePro.bannerFlexContText2
                  : styleMed.bannerFlexContText2
              }
            >
              We provide access to thousands of moonlighter openings for
              physicians, nurses, med techs, rad techs and other medical
              professionals.
            </div>

            <div
              style={
                isMobile || windowDimensions.width > 1366
                  ? stylePro.bannerFlexContText3
                  : styleMed.bannerFlexContText3
              }
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <a href="#registration-form">Register now</a>
                <img
                  src={arrowright}
                  alt="arrow-right"
                  style={
                    isMobile || windowDimensions.width > 1366
                      ? stylePro.bannerFlexContText3Arrow
                      : styleMed.bannerFlexContText3Arrow
                  }
                />
              </div>
            </div>
          </div>
          <div style={stylePro.bannerFlexCont2}>
            <img
              ref={imgRef}
              alt="header-image1"
              style={{
                width: "100%",
                height: "auto",
                display: showImg ? "" : "none",
              }}
            />
          </div>
        </div>
      </div>
      {/* section divs */}
      <div style={stylePro.sectionDiv}>
        {/* section 1 */}
        <div style={stylePro.sectionSubDiv}>
          <div style={stylePro.sectionSubDivContTable}>
            <div style={stylePro.sectionSubDivContCell}>
              <div
                style={
                  isMobile || windowDimensions.width > 1366
                    ? stylePro.sectionSubDivContHeader
                    : styleMed.sectionSubDivContHeader
                }
              >
                Access hundreds of flexible job opportunities from trusted
                healthcare providers
              </div>
              <div
                style={
                  isMobile || windowDimensions.width > 1366
                    ? stylePro.sectionSubDivContText
                    : styleMed.sectionSubDivContText
                }
              >
                We make it easy for you to find jobs for your profession based
                on your preferred location and schedule.
              </div>
            </div>
          </div>
          <div style={stylePro.sectionSubDivImage}>
            <img
              ref={imgRef1}
              alt="section-image1"
              style={{
                width: "100%",
                height: "auto",
                display: showImg ? "" : "none",
              }}
            />
          </div>
        </div>
        {/* section 2 */}
        <div style={stylePro.sectionSubDivReversed}>
          <div style={stylePro.sectionSubDivContTable}>
            <div style={stylePro.sectionSubDivContCell}>
              <div
                style={
                  isMobile || windowDimensions.width > 1366
                    ? stylePro.sectionSubDivContHeader
                    : styleMed.sectionSubDivContHeader
                }
              >
                Swift payouts - because you deserve them!
              </div>
              <div
                style={
                  isMobile || windowDimensions.width > 1366
                    ? stylePro.sectionSubDivContText
                    : styleMed.sectionSubDivContText
                }
              >
                We streamline HR processes so you receive your job's pay in less
                than 24 hours.
              </div>
            </div>
          </div>
          <div style={stylePro.sectionSubDivImage}>
            <img
              ref={imgRef2}
              alt="section-image2"
              style={{
                width: "100%",
                height: "auto",
                display: showImg ? "" : "none",
              }}
            />
          </div>
        </div>
        {/* section 3 */}
        <div style={stylePro.sectionSubDiv}>
          <div style={stylePro.sectionSubDivContTable}>
            <div style={stylePro.sectionSubDivContCell}>
              <div
                style={
                  isMobile || windowDimensions.width > 1366
                    ? stylePro.sectionSubDivContHeader
                    : styleMed.sectionSubDivContHeader
                }
              >
                Turn flexible jobs into a reliable income source
              </div>
              <div
                style={
                  isMobile || windowDimensions.width > 1366
                    ? stylePro.sectionSubDivContText
                    : styleMed.sectionSubDivContText
                }
              >
                Keep track of all your moonlighting jobs in one app. Line up as
                many shifts as you want to pursue your financial goals.
              </div>
            </div>
          </div>
          <div style={stylePro.sectionSubDivImage}>
            <img
              ref={imgRef3}
              alt="section-image3"
              style={{
                width: "100%",
                height: "auto",
                display: showImg ? "" : "none",
              }}
            />
          </div>
        </div>
      </div>
      {/* signup div */}
      <div style={stylePro.signupDiv} id="registration-form">
        <div style={stylePro.signupDivCont}>
          <div style={stylePro.signupDivCont1}>
            <div
              style={
                isMobile || windowDimensions.width > 1366
                  ? stylePro.singupDivContText
                  : styleMed.singupDivContText
              }
            >
              Register now to be among the first to know when we launch.
            </div>
          </div>
          <div style={stylePro.signupDivContTable2}>
            <div style={stylePro.signupDivContCell}>
              {!formSubmitted ? (
                <>
                  <div style={stylePro.signupDivInputCont}>
                    <div style={stylePro.signupDivInputLabel}>
                      <label for="name">Name</label>
                      {/* <span style={{marginLeft: "30px", color: "red"}}>Must not be empty</span> */}
                    </div>
                    <input
                      id="name"
                      style={
                        errorName
                          ? stylePro.signupDivInputError
                          : stylePro.signupDivInput
                      }
                      onChange={handleNameChange}
                      value={name}
                    />
                  </div>
                  <div style={stylePro.signupDivInputCont}>
                    <div style={stylePro.signupDivInputLabel}>
                      <label for="email">Email Address</label>
                    </div>
                    <input
                      id="email"
                      style={
                        errorEmail
                          ? stylePro.signupDivInputError
                          : stylePro.signupDivInput
                      }
                      onChange={handleEmailChange}
                      value={email}
                    />
                  </div>
                  <div style={stylePro.signupDivSelectCont}>
                    <div style={stylePro.signupDivInputLabel}>
                      <label for="profession">Profession</label>
                    </div>
                    {/* Nurse, Physician, Medtech, Radtech, Other*/}
                    {/* <select style={ profession === "" ? stylePro.signupDivSelectDisabled : stylePro.signupDivSelect} onChange={handleSelectChange} value={profession}>
                                    <option hidden disabled value=""> Select a medical profession</option>
                                    <option>Nurse</option>
                                    <option>Physician</option>
                                    <option>Medtech</option>
                                    <option>Radtech</option>
                                    <option>Other</option>
                            </select> */}
                    <Select
                      id="profession"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? "none" : "none",
                          outline: state.isFocused ? "none" : "none",
                          border: errorProfession ? "1px solid red" : "1px",
                          backgroundColor: errorProfession
                            ? "#ffdddd"
                            : "#ffffff",
                          boxShadow: "none",
                          height: "40px",
                          borderRadius: "8px",
                        }),
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      style={stylePro.signupDivSelect}
                      placeholder="Select a medical profession"
                      defaultValue={profession}
                      onChange={setProfession}
                      value={profession}
                      options={options}
                    />
                  </div>
                </>
              ) : formError ? (
                <div style={stylePro.singupDivContSubmitted}>
                  <div style={{ display: "table-cell" }}>
                    Oops! We encountered an error during the registration.
                    <br />
                    While we look into this, please try again later.
                    <br />
                    <button
                      style={stylePro.signupDivButton2}
                      onClick={reloadForm}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              ) : (
                <div style={stylePro.singupDivContSubmitted}>
                  <div style={{ display: "table-cell" }}>
                    Thank you for registering your interest.
                    <br />
                    You will hear from us soon!
                  </div>
                </div>
              )}
              {formSubmitted ? (
                <div style={{ height: "70px" }}></div>
              ) : loading ? (
                <button
                  style={stylePro.signupDivButton}
                  onClick={register}
                  disabled
                >
                  Register
                </button>
              ) : (
                <button style={stylePro.signupDivButton} onClick={register}>
                  Register
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* contact div */}
      {isMobile ? <FooterMobile /> : <FooterDesktop />}
    </div>
  );
};

export default LandingProfessional;
